import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "reactstrap";
import { Spinner } from "reactstrap";
import moment from "moment/moment";
import { Input } from "reactstrap";
import Pagination from "../../Components/Paginate";
import { getMemberShipReport } from "../../redux/actions/MemberShipAction";
import { CSVLink } from 'react-csv'
import { BiSolidFileExport } from "react-icons/bi";
import axios from "axios";

const MembershipReport = () => {
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth },
    MemberShipReducer: { memberShipRegistration, loading, MemberShipRegistrationCount },
    PermissionReducer: { allowedPermissions },
  } = useSelector((state) => state);
  const [page, setPage] = useState(0);
  const [paginate, setPaginate] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("desc");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [subCategoryFilter, setSubCategoryFilter] = useState("");
  const [memberShipFilter, setMemberShipFilter] = useState("");
  const [paymentGateway, setPaymentGateway] = useState("");
  const [userTypeFilter, setUserTypeFilter] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [subCategories, setSubCategories] = useState([]);
  const [memberships, setMemberships] = useState([]);

  useEffect(() => {
    dispatch(getMemberShipReport(paginate, page, search, sort, categoryFilter, subCategoryFilter, memberShipFilter, paymentGateway, userTypeFilter, paymentStatus, startDate, endDate));
  }, [paginate, page, search, sort, categoryFilter, subCategoryFilter, memberShipFilter, paymentGateway, userTypeFilter, paymentStatus, startDate, endDate]);

  useEffect(() => {
    if (categoryFilter) {
      axios(`/api/web/v1/get-sectorBy-type/${categoryFilter}`)
        .then(({ data }) => {
          setSubCategories(data?.data);
        })
        .catch((err) => console.error(err));
    } else {
      setSubCategories([]);
    }
  }, [categoryFilter]);
  useEffect(() => {
    axios("/api/admin/v1/members-list", {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
      .then(({ data }) => {
        setMemberships(data?.memberShips);
      })
      .catch((err) => console.error(err));

  }, [auth]);

  const csvHead = [['membership', 'name', 'email', 'mobile', 'amount', 'category', 'sub category', 'user type', 'payment gateway', 'payment status']];
  const csvData = memberShipRegistration.map((cv) => [
    cv?.MemberShip?.name,
    cv?.name,
    cv?.email,
    cv?.mobile,
    cv?.amount,
    cv?.["category-type"]?.type,
    cv?.subCategory?.name_english,
    cv?.userType,
    cv?.paymentGatwaye,
    cv?.paymentStatus,
  ]);
  const exportData = csvHead.concat(csvData);
  return (
    <div>
      <div className="row my-3">
        <div className="col-md-12">
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="mb-0 text-white">MemberShip Registration</h5>
          </div>
        </div>
      </div>
      <div
        className="bg-white px-3 py-4 rounded-2  my-4"
        style={{ boxShadow: "4px 4px 18px rgba(0,0,0,0.15)" }}
      >
        <div className="row">
          <div className="col-md-3 mb-2">
            <Input
              size={"sm"}
              value={search}
              placeholder="Search By Name, Email or Mobile"
              onChange={({ target: { value } }) => setSearch(value)}
            />
          </div>
          <div className="col-md-2 mb-2">
            <Input
              type="select"
              size={"sm"}
              value={categoryFilter}
              onChange={({ target }) => setCategoryFilter(target.value)}
            >
              <option value="">Select Category</option>
              <option value="1">Industrial</option>
              <option value="2">Professional</option>
            </Input>
          </div>
          <div className="col-md-2 mb-2">
            <Input
              type="select"
              size={"sm"}
              value={subCategoryFilter}
              onChange={({ target }) => setSubCategoryFilter(target.value)}
            >
              <option value="">Select SubCategory</option>
              {subCategories?.map?.((item) => {
                return (
                  <option value={item.id} key={item.id}>
                    {item?.name_english}
                  </option>
                );
              })}
            </Input>
          </div>
          <div className="col-md-2 mb-2">
            <Input
              type="select"
              size={"sm"}
              value={memberShipFilter}
              onChange={({ target }) => setMemberShipFilter(target.value)}
            >
              <option value="">Select MemberShip</option>
              {memberships?.map?.((item) => {
                return (
                  <option value={item.id} key={item.id}>
                    {item?.name} ( {item?.memberShipType} )
                  </option>
                );
              })}
            </Input>
          </div>
          <div className="col-md-2 mb-2">
            <Input
              type="select"
              size={"sm"}
              value={paymentGateway}
              onChange={({ target }) => setPaymentGateway(target.value)}
            >
              <option value="">Select Gateway Type</option>
              <option value="wallet">Wallet</option>
              <option value="razor_pay">Razor Pay</option>
            </Input>
          </div>
          <div className="col-md-2 mb-2">
            <Input
              type="select"
              size={"sm"}
              value={userTypeFilter}
              onChange={({ target }) => setUserTypeFilter(target.value)}
            >
              <option value="">Select User Type</option>
              <option value="user">User</option>
              <option value="consultant">Consultant</option>
            </Input>
          </div>
          <div className="col-md-2 mb-2">
            <Input
              type="select"
              size={"sm"}
              value={paymentStatus}
              onChange={({ target }) => setPaymentStatus(target.value)}
            >
              <option value="">Select Payment Status</option>
              <option value="SUCCESS">Success</option>
              <option value="PENDING">Pending</option>
              <option value="FAILED">Failed</option>
            </Input>
          </div>
          <div className="col-md-2 mb-2">
            <Input
              type="date"
              className="form-control-sm"
              onChange={e =>
                setStartDate(
                  e.target.value
                )
              }
              value={startDate}
            />
          </div>
          <div className="col-md-2 mb-2">
            <Input
              type="date"
              className="form-control-sm"
              onChange={e =>
                setEndDate(
                  e.target.value
                )
              }
              value={endDate}
            />
          </div>
          <div className="col-md-2 mb-2">
            <Input
              type="select"
              size={"sm"}
              value={sort}
              onChange={({ target }) => setSort(target.value)}
            >
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </Input>
          </div>
          {allowedPermissions?.some?.(
            (item) =>
              item?.permission?.moduleType ===
              "MEMBERSHIPREGISTRATION" &&
              item?.permission?.permission === "export"
          ) && (
              <div className="col-md-1">
                <CSVLink
                  filename={
                    "membership-registration-list.csv"
                  }
                  className="text-black ms-3"
                  title="Export To CSV"
                  data={exportData}
                >
                  <BiSolidFileExport size={25} />
                </CSVLink>
              </div>
            )}
          {loading ? (
            <div className="d-flex justify-content-center align-items-center mx-auto">
              <Spinner color="primary" />
            </div>
          ) : (
            <div className="col-md-12">
              <Table hover responsive>
                <thead>
                  <tr>
                    <th>SL. No.</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th>Category</th>
                    <th>SubCategory</th>
                    <th>MemberShip</th>
                    <th>Amount</th>
                    <th>Type</th>
                    <th>Payment Gateway</th>
                    <th>Payment Status</th>
                    <th>start Date</th>
                    <th>end Date</th>
                  </tr>
                </thead>

                <tbody>
                  {(memberShipRegistration ? memberShipRegistration : [])?.map?.((item, index) => {
                    return (
                      <tr key={item.id}>
                        <th scope="row">{index + 1}</th>
                        <td>{item?.name}</td>
                        <td>{item?.email}</td>
                        <td>{item?.mobile}</td>
                        <td>{item?.["category-type"]?.type}</td>
                        <td>{item?.subCategory?.name_english}</td>
                        <td>{item?.MemberShip?.name}</td>
                        <td>{item?.amount}</td>
                        <td>{item?.userType}</td>
                        <td>{(item?.paymentGatwaye === "razor_pay") ? "Razor Pay" : "Wallet"}</td>
                        <td>{item?.paymentStatus}</td>
                        <td>{moment(item?.startDate).format("DD-MM-yyyy")}</td>
                        <td>{moment(item?.endDate).format("DD-MM-yyyy")}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex">
            <select
              id="paginate"
              onChange={(e) => setPaginate(e.target.value)}
              value={paginate}
              className="paging custom-select custom-select-page"
            >
              <option value="10" selected>
                10
              </option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="500">500</option>
            </select>
          </div>
          <Pagination
            pageCount={MemberShipRegistrationCount / paginate}
            onChange={(e) => setPage(e.selected)}
          />
        </div>
      </div>
    </div>
  );
};

export default MembershipReport;
