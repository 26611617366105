import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { CSVLink } from 'react-csv';
import { FcOpenedFolder } from "react-icons/fc";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { FiAlertTriangle } from "react-icons/fi";
import moment from "moment";
import { BiSolidFileExport } from "react-icons/bi";
import { getLockerDetails, getUserWallet, getUsercallHistory } from "../redux/actions/indexActions";
import { FaDownload, FaRegBuilding, FaUser } from "react-icons/fa";

const UserDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth },
    indexReducer: { lockerDocs, loading, walletAmount, callHistory },
    PermissionReducer: { allowedPermissions },
  } = useSelector((state) => state);

  // console.log("call history users ki =>", callHistory);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const userType = queryParams.get('userType');

  useEffect(() => {
    dispatch(getLockerDetails(id, userType));
  }, [id, userType]);

  const csvHead = [
    [lockerDocs?.name, lockerDocs?.mobile, lockerDocs?.email],
    ['Transaction Amount', 'Transaction Type', 'Transaction Detail']
  ];

  const csvData = walletAmount?.transaction_detail?.map((cv) => [
    cv?.transaction_amount,
    cv?.transaction_type,
    cv?.transaction_detail,
  ]) || [];  // Ensure it's an empty array if undefined

  const exportData = csvHead.concat(csvData);

  useEffect(() => {
    dispatch(getUserWallet(id, userType));
  }, [id, userType]);

  const [page, setPage] = useState(0);
  const [history, setHistory] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(getUsercallHistory(10, page, search, id, "user"));
  }, [id, page]);

  const toggle = () => setIsOpen((prev) => !prev);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  const [activeFolderIndex, setActiveFolderIndex] = useState(0);
  const handleFolderToggle = (index) => {
    setActiveFolderIndex(activeFolderIndex === index ? -1 : index);
  };

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const totalItems = walletAmount?.transaction_detail?.length || 0;
  const pageCount = Math.ceil(totalItems / itemsPerPage);

  const currentItems = walletAmount?.transaction_detail?.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  ) || [];  // Ensure it's an empty array if undefined

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getDuration = (startTime, endTime) => {
    const start = moment.utc(startTime);
    const end = moment.utc(endTime);
    if (!start.isValid() || !end.isValid()) {
      return "00";
    }
    const duration = moment.duration(end.diff(start));
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return `${hours}h ${minutes}m ${seconds}s`;
  };

  return (
    <div>
      <div className="row my-3 bg-white px-3 py-4 rounded-2 my-4"
        style={{ boxShadow: "4px 4px 18px rgba(0,0,0,0.15)" }}>
        <div className="col-md-6">
          <h1 className="text-dark">User Detils</h1>
        </div>
        <div className="col-md-6">
          <div className="d-flex align-items-center justify-content-between">
            <h3 className={`btn ${history ? 'btn-outline-warning' : 'btn-warning'}`} onClick={() => setHistory(false)}> Call History</h3>
            <h3 className={`btn ${history ? 'btn-warning' : 'btn-outline-warning'}`}
              onClick={() => setHistory(true)}> Wallet History</h3>
          </div>
        </div>
        <div className="col-md-6">
          <div>
            <div className="row gy-3">
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-3">
                    <span className="text-sm fw-semibold text-secondary">Name</span>
                  </div>
                  <div className="col-md-9">
                    <span>{lockerDocs?.name}</span>
                  </div>

                  <div className="col-md-3">
                    <span className="text-sm fw-semibold text-secondary">Mobile</span>
                  </div>
                  <div className="col-md-9">
                    <span>{lockerDocs?.mobile || "N/A"}</span>
                  </div>

                  <div className="col-md-3">
                    <span className="text-sm fw-semibold text-secondary">Email</span>
                  </div>
                  <div className="col-md-9">
                    <span>{lockerDocs?.email || "N/A"}</span>
                  </div>

                  <div className="col-md-3">
                    <span className="text-sm fw-semibold text-secondary">Gender</span>
                  </div>
                  <div className="col-md-9">
                    <span>{lockerDocs?.gender || "N/A"}</span>
                  </div>

                  <div className="col-md-3">
                    <span className="text-sm fw-semibold text-secondary">City</span>
                  </div>
                  <div className="col-md-9">
                    <span>{lockerDocs?.city || "N/A"}</span>
                  </div>

                  <div className="col-md-3">
                    <span className="text-sm fw-semibold text-secondary">State</span>
                  </div>
                  <div className="col-md-9">
                    <span>{lockerDocs?.state || "N/A"}</span>
                  </div>
                </div>
              </div>
              {lockerDocs?.lockerGroups?.length ? (
                <div className="col-md-12">
                  <div className="py-2 px-4 bg-light mt-3">
                    <span><b> Locker Groups</b></span>
                  </div>
                  <div className="mt-3">
                    <div className="accordion" id="lockerGroupsAccordion">
                      {lockerDocs?.lockerGroups?.map((lockerGroup, groupIndex) => (
                        <div className="accordion-item" key={groupIndex + 1}>
                          <h2 className="accordion-header" id={`headingGroup${groupIndex + 1}`}>
                            <button
                              className={`accordion-button ${activeIndex === groupIndex + 1 ? '' : 'collapsed'}`}
                              onClick={() => handleToggle(groupIndex + 1)}
                              aria-expanded={activeIndex === groupIndex + 1}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapseGroup${groupIndex + 1}`}
                              aria-controls={`collapseGroup${groupIndex + 1}`}
                            >
                              <span className="d-flex me-3 pt-1">
                                {lockerGroup?.groupName == "1" ? (
                                  <>
                                    <span className="icon-box-4 bg-color-4 text-white">
                                      <FaRegBuilding size={22} />
                                    </span>
                                    <span className="ms-2 text-dark mt-2">{lockerGroup?.companyName}</span>
                                  </>
                                ) : (
                                  ""
                                )}

                                {lockerGroup?.groupName == "2" ? (
                                  <>
                                    <span className="icon-box-4 bg-color-7 text-white">
                                      <FaUser size={22} />
                                    </span>
                                    <span className="ms-2 text-dark mt-2">{lockerGroup?.companyName}</span>
                                  </>
                                ) : (
                                  ""
                                )}
                              </span>
                            </button>
                          </h2>
                          <div
                            id={`collapseGroup${groupIndex + 1}`}
                            className={`accordion-collapse collapse ${activeIndex === groupIndex + 1 ? 'show' : ''}`}
                            aria-labelledby={`headingGroup${groupIndex + 1}`}
                            data-bs-parent="#lockerGroupsAccordion"
                          >
                            <div className="accordion-body">
                              {lockerGroup?.lockers?.map((lockerDoc, lockerIndex) => (
                                <div className="accordion" id={`lockersAccordion${groupIndex + 1}`} key={lockerIndex + 1}>
                                  <div className="accordion-item mb-3">
                                    <h2 className="accordion-header" id={`headingLocker${groupIndex + 1}-${lockerIndex + 1}`}>
                                      <button
                                        className={`accordion-button ${activeFolderIndex === lockerIndex + 1 ? '' : 'collapsed'}`}
                                        onClick={() => handleFolderToggle(lockerIndex + 1)}
                                        aria-expanded={activeFolderIndex === lockerIndex + 1}
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#collapseLocker${groupIndex + 1}-${lockerIndex + 1}`}
                                        aria-controls={`collapseLocker${groupIndex + 1}-${lockerIndex + 1}`}
                                      >
                                        <span className="icon-box-3 me-2 bg-primary text-white">
                                          <FcOpenedFolder size={22} />
                                        </span>
                                        {lockerDoc?.lockerName}
                                      </button>
                                    </h2>
                                    <div
                                      id={`collapseLocker${groupIndex + 1}-${lockerIndex + 1}`}
                                      className={`accordion-collapse collapse ${activeFolderIndex === lockerIndex + 1 ? 'show' : ''}`}
                                      aria-labelledby={`headingLocker${groupIndex + 1}-${lockerIndex + 1}`}
                                      data-bs-parent={`#lockersAccordion${groupIndex + 1}`}
                                    >
                                      <div className="accordion-body">
                                        <div className="row">
                                          <div className="col-md-6">
                                            <p className="text-secondary">Documents: <b>{lockerDoc?.documents?.length}</b></p>
                                            {lockerDoc?.documents?.map((doc, docIndex) => (
                                              <div className="my-2" key={docIndex + 1}>
                                                <p className="text-dark">{doc?.docName}</p>
                                                <a href={doc?.fileUrl} target="_blank" rel="noopener noreferrer">
                                                  <button className="btn btn-primary btn-sm">
                                                    <BiSolidFileExport className="me-1" /> Download
                                                  </button>
                                                </a>
                                              </div>
                                            ))}
                                          </div>
                                          <div className="col-md-6">
                                            <p className="text-secondary">Notes: <b>{lockerDoc?.notes?.length}</b></p>
                                            {lockerDoc?.notes?.map((note, noteIndex) => (
                                              <div className="my-2" key={noteIndex + 1}>
                                                <p className="text-dark">{note?.noteContent}</p>
                                                <p className="text-muted">{getDuration(note?.startTime, note?.endTime)}</p>
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {history ? (<div className="col-md-6">
          <div>
            <h5 className="mb-0">Wallet Amount</h5>
            <div className="mt-2">
              <div className="row">
                <div className="col-md-12 d-flex justify-content-center">
                  <div className="p-2 rounded" style={{ background: "#007bff", color: "#fff", display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50%' }}>
                    <span className="text-white"><b>Wallet Balance :</b>{walletAmount?.creditAmount - walletAmount?.debitAmount} </span>
                  </div>
                  <span className="text-white"> <CSVLink
                    data={exportData}
                    filename={`wallet-details-${moment().format('YYYY-MM-DD')}.csv`}
                    className="btn btn-outline-primary"
                    target="_blank">
                    <FaDownload /> Export
                  </CSVLink></span>

                </div>
              </div>
              <h6 className="mt-4">Transaction Details</h6>
              <div className="table-responsive">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Transaction Amount</th>
                      <th>Transaction Type</th>
                      <th>Transaction Detail</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.length ? (
                      currentItems.map((item, index) => (
                        <tr key={index}>
                          <td>{item.transaction_amount}</td>
                          <td>{item.transaction_type}</td>
                          <td>{item.transaction_detail}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className="text-center">No transactions found.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {/* Pagination */}
                <div className="d-flex justify-content-between">
                  <button
                    onClick={() => goToPage(Math.max(0, currentPage - 1))}
                    disabled={currentPage === 0}
                    className="btn btn-secondary"
                  >
                    Previous
                  </button>
                  <button
                    onClick={() => goToPage(Math.min(pageCount - 1, currentPage + 1))}
                    disabled={currentPage === pageCount - 1}
                    className="btn btn-secondary"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>) : (<div className="col-md-6">
          <div className="mt-2">
            <div className="row">
              <div className="col-md-12 d-flex justify-content-center">
                <div className="p-2 rounded" style={{ background: "#007bff", color: "#fff", display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80%' }}>
                  <span className="text-white"><b>Call History</b></span>
                </div>
              </div>
            </div>
            <div className="table-responsive mt-5">
              {callHistory.length ? (
                <div className="">
                  {callHistory?.map((item) => {
                    return (
                      <>
                        <div key={item.id} className="d-flex hover-class border align-items-center justify-content-between py-3 px-4">
                          <div className="col-md-6 col-8">
                            <div className="d-flex align-items-center">
                              <p className="mb-0 fs-16 text-capitalize">{item.status}</p>
                              <span className="text-secondary small">
                                {moment(item.createdAt).format(
                                  "MMMM Do YYYY, hh:mm:ss A"
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="text-end col-md-3 col-4">
                            <span className="small">Booking Id  {item.bookingId} </span> <br />
                            <span className="text-muted"> Duration: {getDuration(item.start_time, item.end_time)}</span>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              ) : (
                <div className="py-5">
                  <p className="text-center mb-0 text-dark">
                    Sorry, you dont have any calls
                  </p>
                </div>
              )}

            </div>
          </div>

        </div>)}


      </div>
    </div>
  );
};

export default UserDetail;
