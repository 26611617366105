import axios from "axios";

export const getRoles = (token) => async (dispatch) => {
  try {
    dispatch({ type: "GET_ROLES_PENDING" });
    const response = await axios("/api/admin/v1/roles", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: "GET_ROLES_FULFILLED", payload: response.data?.roles });
  } catch (error) {
    dispatch({ type: "GET_ROLES_FAILED", payload: error.response.data });
  }
};

export const getCategories =
  (token, limit, page, search = "") =>
    async (dispatch) => {
      try {
        dispatch({ type: "GET_CATEGORIES_PENDING" });
        const response = await axios(
          `/api/admin/v1/categories?limit=${limit || 10}&page=${page || 0
          }&search=${search}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        dispatch({
          type: "GET_CATEGORIES_FULFILLED",
          payload: response.data.categories,
          categoriesCount: response.data?.categoriesCount,
        });
      } catch (error) {

        dispatch({ type: "GET_CATEGORIES_FAILED", payload: error.response.data });
      }
    };

export const getSubCategories =
  (token, paginate, page, search = "", category = "", sort = "") =>
    async (dispatch) => {
      try {
        dispatch({ type: "GET_SUBCATEGORIES_PENDING" });
        const response = await axios(
          `/api/admin/v1/subcategories?limit=${paginate || 10}&page=${page || 0
          }&search=${search}&category=${category}&sort=${sort}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        dispatch({
          type: "GET_SUBCATEGORIES_FULFILLED",
          payload: response.data.subCategories,
          subCategoryCount: response.data?.subCategoriesCount,
        });
      } catch (error) {
        dispatch({
          type: "GET_SUBCATEGORIES_FAILED",
          payload: error.response.data,
        });
      }
    };

export const getSubSubCategories =
  (
    token,
    limit,
    page,
    search = "",
    paginate,
    categoryFilter,
    subCategoryFilter,
    sort
  ) =>
    async (dispatch) => {
      try {
        dispatch({ type: "GET_SUB_SUB_CATEGORIS_PENDING" });
        const response = await axios(
          `/api/admin/v1/sub/sub/categories?limit=${limit || 10
          }&page=${page}&search=${search}&paginate=${paginate}&categoryFilter=${categoryFilter}&subCategoryFilter=${subCategoryFilter}&sort=${sort}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        dispatch({
          type: "GET_SUB_SUB_CATEGORIS_FULFILLED",
          payload: response.data?.subSubCategories,
          subSubCategorycount: response.data?.subSubCategoriesCount,
        });
      } catch (error) {
        dispatch({
          type: "GET_SUB_SUB_CATEGORIS_REJECTED",
          payload: error.response.data,
        });
      }
    };

export const getExpertVideos =
  (
    token,
    limit,
    page,
    search = "",
    sort = "desc",
    categoryFilter = "",
    subCategoryFilter = "",
    subSubSubCategoryFilter = ""
  ) =>
    async (dispatch) => {
      try {
        dispatch({ type: "GET_EXPERTVIDEOS_PENDING" });
        const response = await axios(
          `/api/admin/v1/expertvideos/?limit=${limit || 10}&page=${page || 0
          }&search=${search}&sort=${sort}&categoryFilter=${categoryFilter}&subCategoryFilter=${subCategoryFilter}&subSubCategoryFilter=${subSubSubCategoryFilter}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        dispatch({
          type: "GET_EXPERTVIDEOS_FULFILLED",
          payload: response.data.expertVideos,
          expertVideosCount: response.data?.expertVideosCount,
        });
      } catch (error) {
        dispatch({
          type: "GET_EXPERTVIDEOS_FAILED",
          payload: error.response.data,
        });
      }
    };

export const getOfferList =
  (token, limit, page, search = "", sort = "") =>
    async (dispatch) => {
      try {
        dispatch({ type: "GET_OFFERLIST_PENDING" });
        const response = await axios(
          `/api/admin/v1/offer-list/?limit=${limit || 10}&page=${page || 0
          }&search=${search}&sort=${sort}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        dispatch({
          type: "GET_OFFERLIST_FULFILLED",
          payload: response.data.OfferLists,
          offerCount: response.data?.offerCount,
        });
      } catch (error) {
        dispatch({
          type: "GET_OFFERLIST_FAILED",
          payload: error.response.data,
        });
      }
    };

export const getBannerList =
  (token, limit, page, search = "", sort = "") =>
    async (dispatch) => {
      try {
        dispatch({ type: "GET_BANNERLIST_PENDING" });
        const response = await axios(
          `/api/admin/v1/banner-list/?limit=${limit || 10}&page=${page || 0
          }&search=${search}&sort=${sort}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        dispatch({
          type: "GET_BANNERLIST_FULFILLED",
          payload: response.data.BannerLists,
          bannerCount: response.data?.bannerCount,
        });
      } catch (error) {
        dispatch({
          type: "GET_BANNERLIST_FAILED",
          payload: error.response.data,
        });
      }
    };

export const getSeoList =
  (token, limit, page, search = "", sort = "desc") =>
    async (dispatch) => {
      try {
        dispatch({ type: "GET_SEO_LIST_PENDING" });
        const response = await axios(
          `/api/admin/v1/seo-list/?limit=${limit || 10}&page=${page || 0
          }&search=${search}&sort=${sort}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        dispatch({
          type: "GET_SEO_LIST_FULFILLED",
          payload: response.data.seoLists,
          seoCount: response.data?.seoCount,
        });
      } catch (error) {
        dispatch({
          type: "GET_SEO_LIST_FAILED",
          payload: error.response.data,
        });
      }
    };


export const getMemberships = (token) => async (dispatch) => {
  try {
    dispatch({ type: "GET_MEMBERSHIP_LIST_PENDING" });
    const response = await axios("/api/admin/v1/members-list", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: "GET_MEMBERSHIP_LIST_FULFILLED",
      payload: response.data?.memberShips,
    });
  } catch (error) {
    dispatch({
      type: "GET_MEMBERSHIP_LIST_REJECTED",
      payload: error.response.data,
    });
  }
};

export const getLockerDetails =
  (id, userType) => async (dispatch) => {
    try {
      dispatch({ type: "GET_LOCKER_DETAIL_PENDING" });
      const response = await axios(
        `/api/admin/v1/locker-doc-detail/${id}?userType=${userType}`
      );
      dispatch({
        type: "GET_LOCKER_DETAIL_FULFILLED",
        payload: response.data?.data,
      });
    } catch (error) {
      dispatch({ type: "GET_LOCKER_DETAIL_FAILED", payload: error.response.data });
    }
  };

export const getUserWallet =
  (id, userType) => async (dispatch) => {
    try {
      dispatch({ type: "GET_USER_WALLET_PENDING" });
      const response = await axios(`/api/admin/v1/user-wallet/${id}?userType=${userType}`);
      dispatch({
        type: "GET_USER_WALLET_FULFILLED",
        payload: response.data?.data,
      });
    } catch (error) {
      dispatch({ type: "GET_USER_WALLET_REJECTED", error: error.response.data });
    }
  };
export const getUsercallHistory = (limit, page, search, id, userType) => async (dispatch) => {
  try {
    dispatch({ type: "GET_USER_CALL_HISTORY_PENDING" })
    const response = await axios(`/api/admin/v1/get-user-call-history/${id}?userType=${userType}&limit=${limit}&page=${page}&search=${search}`)
    dispatch({ type: "GET_USER_CALL_HISTORY_FULFILLED", payload: response.data })
  } catch (error) {
    dispatch({ type: "GET_USER_CALL_HISTORY_REJECTED", error: error.response.data })
  }
}
export const getServiceUserDetails =
  (id) =>
    async (dispatch) => {
      try {
        dispatch({ type: "GET_SERVICE_USER_DETAIL_PENDING" });
        const response = await axios(
          `/api/admin/v1/get-user-service-detail/${id}`
        );
        dispatch({
          type: "GET_SERVICE_USER_DETAIL_FULFILLED",
          payload: response.data?.data,
        });
      } catch (error) {
        dispatch({ type: "GET_SERVICE_USER_DETAIL_FAILED", payload: error.response.data });
      }
    };

export const getServiceCategories =
  (token, limit, page, search = "") =>
    async (dispatch) => {
      try {
        dispatch({ type: "GET_CATEGORIES_PENDING" });
        const response = await axios(
          `/api/admin/v1/service-categories?limit=${limit || 10}&page=${page || 0
          }&search=${search}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        dispatch({
          type: "GET_CATEGORIES_FULFILLED",
          payload: response.data.categories,
          categoriesCount: response.data?.categoriesCount,
        });
      } catch (error) {
        dispatch({ type: "GET_CATEGORIES_FAILED", payload: error.response.data });
      }
    };

export const getServiceSubCategories =
  (
    token,
    limit,
    page,
    search = "",
    paginate,
    categoryFilter,
    sort
  ) =>
    async (dispatch) => {
      try {
        dispatch({ type: "GET_SERVICE_SUB_CATEGORIS_PENDING" });
        const response = await axios(
          `/api/admin/v1/service/sub/categories?limit=${limit || 10
          }&page=${page}&search=${search}&paginate=${paginate}&categoryFilter=${categoryFilter}&sort=${sort}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        dispatch({
          type: "GET_SERVICE_SUB_CATEGORIS_FULFILLED",
          payload: response.data?.serviceSubCategories,
          serviceSubCategoriescount: response.data?.serviceSubCategoriesCount,
        });
      } catch (error) {
        dispatch({
          type: "GET_SERVICE_SUB_CATEGORIS_REJECTED",
          payload: error.response.data,
        });
      }
    };


export const getServices =
  (token, limit, page, search = "") =>
    async (dispatch) => {
      try {
        dispatch({ type: "GET_SERVICES_PENDING" });
        const response = await axios(
          `/api/admin/v1/services?limit=${limit || 10}&page=${page || 0
          }&search=${search}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        dispatch({
          type: "GET_SERVICES_FULFILLED",
          payload: response.data.services,
          servicesCount: response.data?.servicesCount,
        });
      } catch (error) {
        dispatch({ type: "GET_SERVICES_FAILED", payload: error.response.data });
      }
    };

export const getServiceCategory =
  (token) =>
    async (dispatch) => {
      try {
        dispatch({ type: "GET_SERVICES_CATEGORY_PENDING" });
        const response = await axios(
          `/api/admin/v1/getServicesCategoryDrop`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        dispatch({
          type: "GET_SERVICES_CATEGORY_FULFILLED",
          payload: response.data.servicesCategories,
        });
      } catch (error) {
        dispatch({ type: "GET_SERVICES_CATEGORY_FAILED", payload: error.response.data });
      }
    };

export const getServiceSubCategoriesDrop =
  (token, categoryId) =>
    async (dispatch) => {
      try {
        dispatch({ type: "GET_SERVICES_SUBCATEGORY_PENDING" });
        const response = await axios(
          `/api/admin/v1/getServiceSubCategoriesDrop?categoryId=${categoryId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        dispatch({
          type: "GET_SERVICES_SUBCATEGORY_FULFILLED",
          payload: response.data.serviceSubCategories,
        });
      } catch (error) {
        dispatch({ type: "GET_SERVICES_SUBCATEGORY_FAILED", payload: error.response.data });
      }
    };
export const getEnquiries = (token, paginate, page, search, sort) => async (dispatch) => {
  try {
    dispatch({ type: "GET_ENQUIRIES_PENDING" });
    const response = await axios(
      `/api/admin/v1/enquiries-list?&search=${search}&limit=${paginate || 10}&page=${page || 0
      }&sort=${sort}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: "GET_ENQUIRIES_FULFILLED",
      payload: response.data.enquiries,
      enquiriesCount: response.data.enquiriesCount.count,
    });
  } catch (error) {
    dispatch({ type: "GET_ENQUIRIES_FAILED", payload: error.response.data });
  }
};