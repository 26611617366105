import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Input, Label, FormGroup, Button } from "reactstrap";
import { useDispatch } from "react-redux";
import { getCategoryType } from "../../redux/actions/ConsultantAction";
import CKEditor from "react-ckeditor-component";
import { getServiceCategory, getServiceSubCategoriesDrop } from "../../redux/actions/indexActions";
import { AiFillDelete, AiOutlinePlus } from "react-icons/ai";


const ServiceCreate = () => {
  const { id } = useParams();
  const [error, setErrors] = useState({});
  const [text, setText] = useState("");
  const [category, setCategory] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    amount: 0,
    amount_with_gst: 0,
    hindiName: "",
    serviceCategory: "",
    serviceSubCategory: "",
    avatar: "",
    files: [],
    hindifile: [],
    avatar_english: [],
    check_list: [],
    avatar_hindi: [],
    shortDescription: "",
    description: "",
    url: "",
    videoUrl: "",
    hindiVideoUrl: "",
    status: 1,
    type: "Free",
    features: [],
    addFeatureValue: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    AuthReducer: { auth },
    indexReducer: { errors, servicesCategories, serviceSubCategories },
  } = useSelector((state) => state);

  const inputHandler = (e) => {
    const { name, value } = e.target;
    if (name === "status") {
      setFormData({ ...formData, [name]: e.target.checked ? 1 : 0 });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const changeCategory = (e) => {
    inputHandler(e);
    dispatch(getServiceSubCategoriesDrop(auth.token, e.target.value));
  }
  const handleImageChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setFormData({
      ...formData,
      files: [...formData.files, ...newFiles]
    });

    console.log(formData.files)
  };
  const handleText = (e) => {
    const description = e.editor.getData();
    setText(description);
  }
  const handelTypeChange = (e) => {
    inputHandler(e);
    setFormData({ ...formData, [e.target.name]: e.target.value ? e.target.value : "Free" });
  }
  useEffect(() => {
    dispatch(getServiceCategory(auth.token));
  }, [auth]);

  const handleSubmit = () => {
    const form = new FormData();
    form.append("serviceCategory", formData.serviceCategory);
    form.append("serviceSubCategory", formData.serviceSubCategory);
    form.append("name", formData.name);
    form.append("hindiName", formData.hindiName);
    form.append("description", text);
    form.append('files', formData.files);
    form.append('avatar_english', formData.avatar_english);
    form.append('check_list', formData.check_list);
    form.append('features', formData.features ? JSON.stringify(formData.features) : formData.features);
    form.append('type', formData.type);
    form.append('url', formData.url);
    form.append('videoUrl', formData.videoUrl);
    form.append('hindiVideoUrl', formData.hindiVideoUrl);
    form.append('amount', formData.amount);
    form.append('amount_with_gst', formData.amount_with_gst);
    form.append("status", formData.status);

    for (const pair of form.entries()) {
      // console.log(pair[0], pair[1],);
    }
    axios.put(`/api/admin/v1/service/${id}`, form, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
      .then((res) => {
        toast.success(res.data?.message);
        navigate(-1);
      })
      .catch((err) => {
        console.log("err => ", err.response.data);
        toast.error(err.response.data);
        setErrors(err.response.data);
      });

  };

  useEffect(() => {
    if (id) {
      axios(`/api/admin/v1/service/${id}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
        .then((res) => {
          console.log('services updated =>', res);
          setText(res.data?.category?.short_description);
          dispatch(getServiceSubCategoriesDrop(auth.token, res.data?.category?.service_category_id));
          setFormData((prev) => {
            return {
              ...prev,
              name: res.data?.category?.name,
              hindiName: res.data?.category?.hindi_name,
              serviceCategory: res.data?.category?.service_category_id,
              serviceSubCategory: res.data?.category?.services_sub_category,
              avatar: [],
              files: [],
              hindifile: [],
              avatar_english: [],
              check_list: [],
              avatar_hindi: [],
              shortDescription: res.data?.category?.short_description,
              description: res.data?.category?.hindishort_description,
              url: res.data?.category?.url,
              videoUrl: res.data?.category?.video_url,
              hindiVideoUrl: res.data?.category?.hindi_video_url,
              status: res.data?.category?.name,
              type: res.data?.category?.type,
              amount: res.data?.category?.amount,
              amount_with_gst: res.data?.category?.amount_with_gst,
              features: JSON.parse(res.data?.category?.features),
            };
          });
        })
        .catch((err) => console.log(err));
    }
  }, [id, auth]);

  const addFeature = () => {
    if (formData.addFeatureValue.trim() === '') {
      setErrors({ ...error, addFeatureValue: 'Feature value cannot be empty' });
    } else {
      setFormData({
        ...formData,
        features: formData.features ? [...formData.features, formData.addFeatureValue] : [formData.addFeatureValue],
        addFeatureValue: ''
      });
      setErrors({ ...error, addFeatureValue: '' });
    }
  };

  return (
    <div>
      <div className="row my-3">
        <div className="col-md-12">
          <h5 className="text-white">Edit Service</h5>
        </div>
      </div>
      <div className="bg-white px-3 py-4 rounded-2  my-4">
        <div className="row">
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Category</Label>
              <Input
                invalid={error?.serviceCategory}
                type="select"
                name="serviceCategory"
                value={formData?.serviceCategory}
                onChange={changeCategory}
              >
                <option value="">Select Category</option>
                {servicesCategories?.map?.((item) => {
                  return (
                    <option value={item.id} key={item.id}>
                      {item?.name}
                    </option>
                  );
                })}
              </Input>
              <span className="d-block text-danger">{error?.serviceCategory}</span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Sub Category</Label>
              <Input
                invalid={error?.serviceSubCategory}
                type="select"
                name="serviceSubCategory"
                value={formData?.serviceSubCategory}
                onChange={inputHandler}
              >
                <option value="">Select Sub Category</option>
                {serviceSubCategories?.map?.((item) => {
                  return (
                    <option value={item.id} key={item.id}>
                      {item?.name}
                    </option>
                  );
                })}
              </Input>
              <span className="d-block text-danger">{error?.serviceSubCategory}</span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Service Name
              </Label>
              <Input
                value={formData?.name}
                name="name"
                invalid={error?.name}
                onChange={inputHandler}
              />
              <p className="text-danger">{error?.name}</p>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Service Hindi Name
              </Label>
              <Input
                value={formData?.hindiName}
                name="hindiName"
                invalid={error?.hindiName}
                onChange={inputHandler}
              />
              <p className="text-danger">{error?.hindiName}</p>
            </FormGroup>
          </div>
          <div className="col-md-11 offset-md-1">
            <FormGroup>
              <Label>Description</Label>
              <CKEditor
                activeClass=""
                content={text}
                name="description"
                events={{
                  "change": handleText
                }}
              />
              <span className="d-block text-danger">{error?.description}</span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Thumbnail</Label>
              <Input
                type="file"
                invalid={Boolean(errors?.avatar_english)}
                name="avatar_english"
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.files[0],
                  }))
                }
              />
              <span className="d-block text-danger">{errors?.avatar_english}</span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Check List</Label>
              <Input
                type="file"
                invalid={Boolean(errors?.check_list)}
                name="check_list"
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.files[0],
                  }))
                }
              />
              <span className="d-block text-danger">{errors?.check_list
              }</span>
            </FormGroup>
          </div>

          {/* <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Hindi File</Label>
              <Input
                type="file"
                invalid={Boolean(errors?.avatar_hindi)}
                name="avatar_hindi"
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.files[0],
                  }))
                }
              />
              <span className="d-block text-danger">{errors?.avatar_hindi}</span>
            </FormGroup>
          </div> */}
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>File</Label>
              <Input
                type="file"
                invalid={Boolean(errors?.files)}
                name="files"
                onChange={handleImageChange}
              />
              <span className="d-block text-danger">{errors?.files}</span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                URL
              </Label>
              <Input
                value={formData?.url}
                name="url"
                invalid={error?.url}
                onChange={inputHandler}
              />
              <p className="text-danger">{error?.url}</p>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Video URL
              </Label>
              <Input
                value={formData?.videoUrl}
                name="videoUrl"
                invalid={error?.videoUrl}
                onChange={inputHandler}
              />
              <p className="text-danger">{error?.videoUrl}</p>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Hindi Video URL
              </Label>
              <Input
                value={formData?.hindiVideoUrl}
                name="hindiVideoUrl"
                invalid={error?.hindiVideoUrl}
                onChange={inputHandler}
              />
              <p className="text-danger">{error?.hindiVideoUrl}</p>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup switch>
              <Input
                type="switch"
                checked={formData?.status}
                onChange={inputHandler}
                name="status"
              />
              <Label check>Status</Label>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label style={{ marginRight: 15 }}>Type</Label>
              <Input
                type="radio"
                id="type-free"
                name="type"
                value="Free"
                onChange={handelTypeChange} checked={formData.type === 'Free'}
              />
              <Label className="custom-control-label" style={{ marginInline: 10 }}>Free</Label>
              <Input
                type="radio"
                id="type-paid"
                name="type"
                value="Paid"
                onChange={handelTypeChange} checked={formData.type === 'Paid'}
              />
              <Label className="custom-control-label" style={{ marginInline: 10 }}>Paid</Label>
            </FormGroup>
          </div>
          {formData.type === 'Paid' ?
            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>
                  Amount
                </Label>
                <Input
                  value={formData?.amount}
                  type="number"
                  name="amount"
                  invalid={error?.amount}
                  onChange={inputHandler}
                />
                <p className="text-danger">{error?.amount}</p>
              </FormGroup>
            </div>
            : null}
          {formData.type === 'Paid' ?
            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>
                  Amount With GST
                </Label>
                <Input
                  value={formData?.amount_with_gst}
                  type="number"
                  name="amount_with_gst"
                  invalid={error?.amount_with_gst}
                  onChange={inputHandler}
                />
                <p className="text-danger">{error?.amount_with_gst}</p>
              </FormGroup>
            </div>
            : null}
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Service key feature
              </Label>
              <Input
                value={formData?.addFeatureValue}
                name="addFeatureValue"
                className="col-md-5"
                invalid={error?.addFeatureValue}
                onChange={inputHandler}
              />
              <a size="sm" className="col-md-3 btn btn-primary cursor-pointer text-white text-center"
                onClick={addFeature}>
                <AiOutlinePlus /> Add
              </a>
              <p className="text-danger">{error?.addFeatureValue}</p>
            </FormGroup>
            {formData.features?.length ?

              <div className="row">
                <div className="">
                  <div className="table-responsive">
                    <table className="table table-bordered table-sm">
                      <thead>
                        <tr>
                          <th>
                            Sno.
                          </th>
                          <th>
                            Term
                            &
                            Condition
                          </th>
                          <th>
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {formData.features.map((trm, i) => (
                          <tr key={Math.random()} >
                            <td>{i + 1}</td>
                            <td>{trm}</td>
                            <td>
                              <a
                                className="cursor-pointer"
                                onClick={(e) =>
                                  setFormData({ ...formData, features: formData.features.filter((_, index) => index !== i) })
                                }
                              >
                                <AiFillDelete color="black" />
                              </a>
                            </td>
                          </tr>
                        )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              : null}
          </div>
          <div className="col-md-4 offset-md-4">
            <Button color="primary" onClick={handleSubmit}>
              Submit
            </Button>
            <Button className="ms-3" onClick={() => navigate(-1)}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCreate;
